/** @format */

import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Header1 from "../components/layout/Header1";

const About = () => {
  React.useEffect(() => {
    document.title = " L2reum";
  }, []);
  return (
    <React.Fragment>
      <div className='relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen'>
        <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
          <div className='relative z-10 flex flex-1 flex-col pb-16'>
            <div className='sticky top-[14px] z-10 sm:top-[13px]'>
              <div className='relative'>
                <Header1 />
                <div className='absolute bottom-0 left-0 right-0 transition-opacity duration-300 opacity-0'>
                  <div className='w-full h-[2px] bg-camo-500' />
                </div>
                <div className='absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-0 bg-gradient-to-b from-[rgba(17,20,12,0.95)] from-[27.54%] to-[rgba(37,43,27,0.85)] backdrop-blur-[6px]' />
              </div>
            </div>
            <main className='relative flex h-full flex-1 px-6 pt-6 sm:ml-16 sm:pl-14 sm:pr-14 lg:pt-14 overflow-hidden'>
              <div className='flex flex-1 flex-col items-center sm:mr-16'>
                <div className='flex flex-1 flex-col mt-8 lg:mt-0 mr-auto max-w-6xl lg:ml-auto'>
                  <section id='about'>
                    <div className='flex flex-col-reverse items-center gap-12 sm:flex-row sm:gap-16'>
                      <div className='w-full max-w-prose flex-1 pt-0 sm:pt-6 text-center m-auto'>
                        <h1 className='typography-brand-heading-1 text-white  max-w-[19ch] m-auto'>
                          L2reum is the L2 that helps you earn BNB
                        </h1>
                        <p className='typography-brand-heading-3 pb-6 pt-6 text-gray-300 sm:pb-12'>
                          L2reum allows to optimise on chain assets such as BNB,
                          ETH, BTC, and stable coins at the consensus layer and
                          provide LRT native yield tokens that can be used in
                          various defi applications, including RWA’s,
                          securitization, trenches, staking and
                          lending/borrowing protocols.
                        </p>
                        <div className='flex flex-col flex-wrap items-center justify-center gap-8 sm:flex-row sm:gap-16 m-auto text-center'>
                          <figure>
                            <figcaption className='typography-brand-body-l-caps mb-4 text-white text-center'>
                              Backed By
                            </figcaption>
                            <div className='flex flex-wrap gap-8 items-center justify-center m-auto text-center'>
                              <img
                                alt=''
                                loading='lazy'
                                width={176}
                                decoding='async'
                                className='  max-w-full '
                                data-nimg={1}
                                srcSet='images/pinksale-1.png'
                                src='images/pinksale-1.png'
                                style={{ color: "transparent" }}
                              />
                              <img
                                alt=''
                                loading='lazy'
                                width={144}
                                decoding='async'
                                data-nimg={1}
                                srcSet='images/dexview.svg'
                                src='images/dexview.svg'
                                style={{ color: "transparent" }}
                              />
                              <img
                                alt=''
                                loading='lazy'
                                width={184}
                                decoding='async'
                                data-nimg={1}
                                srcSet='images/full-pancakeswap-logo.svg'
                                src='images/full-pancakeswap-logo.svg'
                                style={{ color: "transparent" }}
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className='mb-12 mt-12 block w-full text-[#E2107B] sm:mb-32 sm:mt-32 sm:hidden'>
                      <svg
                        className='w-full'
                        fill='none'
                        height={15}
                        width={1177}
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                          fill='currentColor'
                        />
                        <circle
                          cx='843.128'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='916.059'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='979.06'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='847.995'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='920.926'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='983.927'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='852.862'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='925.794'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='988.794'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <path
                          d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div>
                      <h2 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl sm:mt-24 sm:max-w-[936px]'>
                        Other L2s don't have yield, so the value of your assets
                        depreciate over time.
                      </h2>
                      <h3
                        className='typography-brand-heading-3 mt-8 max-w-prose text-gray-300
                       sm:mt-6'>
                        L2reum is the first L2 that incorporates native yield.
                        Your balance on L2reum compounds automatically.
                      </h3>

                      <div className='flex flex-col gap-4 sm:flex-row sm:gap-16'>
                        <p className='typography-brand-body-l border-l border-[#E2107B] pl-6 text-gray-100'>
                          L2reum yield comes from ETH staking and RWA protocols.
                          The yield from these decentralized protocols is passed
                          back to L2reum users automatically. The default
                          interest rate on other L2s is 0%. On L2reum, it's 4%
                          for ETH and 5% for stablecoins.
                        </p>
                        <p className='typography-brand-body-l border-l border-r border-[#E2107B] pl-6 text-gray-100'>
                          Our goal as contributors is to grow the on-chain
                          economy with the highest-yield L2 possible. Community
                          members who join our mission will have the opportunity
                          to earn the L2reum Airdrop. The first stage of the
                          airdrop is [now live].
                        </p>
                      </div>
                    </div>
                  </section>
                  <div className='mb-12 mt-12 w-full text-[#E2107B] sm:mb-32 sm:mt-32'>
                    <svg
                      className='w-full'
                      fill='none'
                      height={15}
                      width={1177}
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                        fill='currentColor'
                      />
                      <circle
                        cx='843.128'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='916.059'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='979.06'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='847.995'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='920.926'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='983.927'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='852.862'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='925.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='988.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <path
                        d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                  </div>
                  <section id='how_it_works'>
                    <h2 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl sm:max-w-[936px] text-center m-auto'>
                      How L2reum works
                    </h2>
                    <div className='mt-8 grid grid-cols-1 sm:grid-cols-3  gap-8 sm:mt-16 '>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            className='aspect-square w-12'
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            Liquid ReStaking Token (LRT)
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          Liquid staking introduces a novel avenue for
                          generating passive income from staked assets without
                          the need for locking them up. It serves as an
                          alternative to traditional staking models, where users
                          are typically required to lock their tokens to
                          contribute to the security of proof-of-stake
                          blockchains.
                        </p>
                      </div>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            className='aspect-square w-12'
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            Real World Assets (RWA’s)
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          Decentralized finance (DeFi) protocols, exemplified by
                          Centrifuge, are emulating the fundamental operations
                          of Real World Assets (RWA’s).
                        </p>
                      </div>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            Leverage Staking
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          L2reum is embarking on a strategic partnership with
                          BENQI, with the shared goal of catalyzing the
                          evolution of the sAVAX ecosystem. sAVAX represents
                          AVAX’s staking derivative, delivered by BENQI, the
                          foremost liquid staking and lending protocol on the
                          Avalanche network.
                        </p>
                      </div>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            Trenches Pools
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          In the L2reum proposed model, we constructed tranches
                          featuring diverse risk profiles mirroring the
                          underlying exposure. These tranches span from the
                          least risky (AAA) super senior at the pinnacle of the
                          credit structure to the most lucrative (BB) with
                          higher potential rewards.
                        </p>
                      </div>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            L2reum LRT Staking
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          L2reum enables users to delegate their crypto holdings
                          in one of the staking pools available at L2reum to
                          earn additional rewards. Staking at L2reum is another
                          feature of generating passive income. L2reum holders
                          will be able to participate in rewards in one way.
                        </p>
                      </div>
                      <div className='flex-1'>
                        <div className='mb-6 flex items-center gap-4'>
                          <img
                            alt=''
                            loading='lazy'
                            width={48}
                            height={48}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/logo-cr.svg'
                            src='images/logo-cr.svg'
                            style={{ color: "transparent" }}
                          />
                          <h3 className='typography-brand-heading-3-caps text-white'>
                            Governance
                          </h3>
                        </div>
                        <p className='typography-brand-body-l max-w-prose text-gray-200 sm:pl-16'>
                          L2reum operates under the governance of its community,
                          engaging in forum discussions and voting on proposals
                          through the L2reum Snapshot. Currently, proposals
                          presented to the Snapshot voting system by the CORE
                          carry binding authority if they secure passage with a
                          quorum.
                        </p>
                      </div>
                    </div>
                    <aside className='typography-brand-body mt-8 max-w-prose text-center italic text-[#ff349d] xs:text-left sm:mt-32 sm:max-w-full sm:text-center'>
                      Tranche is committed to high security standards. All smart
                      contracts are independently reviewed by leading auditors
                      and consultants. Contract code is publicly verifiable, and
                      bug bounty programs are available for security
                      professionals to report vulnerabilities.
                    </aside>
                  </section>
                  <div className='mb-12 mt-4 w-full text-[#E2107B] sm:mb-32'>
                    <svg
                      className='w-full'
                      fill='none'
                      height={15}
                      width={1177}
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                        fill='currentColor'
                      />
                      <circle
                        cx='843.128'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='916.059'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='979.06'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='847.995'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='920.926'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='983.927'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='852.862'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='925.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='988.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <path
                        d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                  </div>
                  <section id='why_L2reum'>
                    <h2 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl sm:max-w-[936px] m-auto text-center'>
                      Why a new L2
                    </h2>
                    <h3 className='typography-brand-heading-3 my-8 mt-8 max-w-prose text-gray-300 sm:mt-6  text-center'>
                      The risk-free rate on existing L2s is 0%. It's time to
                      change that.
                    </h3>
                    <div className='flex flex-col gap-4 sm:flex-row sm:gap-16'>
                      <p className='typography-brand-body-l border-l border-[#E2107B] pl-6 text-gray-100'>
                        After the merge, Ethereum provides 4% yield on ETH.
                        On-chain T-Bill protocols provide 5% yield on
                        stablecoins. If users do not match or beat these rates,
                        they are losing money to a form of inflation.
                      </p>
                      <p className='typography-brand-body-l border-l border-r border-[#E2107B] pl-6 text-gray-100'>
                        L2s today do not have this yield. Incorporating ETH and
                        stablecoin yield natively requires a new L2 designed
                        from the ground up. L2reum is an EVM-compatible,
                        optimistic rollup that raises the baseline yield for
                        users and developers without changing the experience
                        cryptonatives expect.
                      </p>
                    </div>
                    <aside className='typography-brand-body mt-8 max-w-prose text-center italic text-[#ff349d] xs:text-left sm:mt-32 sm:max-w-full sm:text-center'>
                      The “risk-free” rate is the theoretical return on an
                      investment with zero risk. In reality, there are no true
                      risk-free rates, only low-risk rates. Even T-Bills carry
                      risk.
                    </aside>
                  </section>
                  <div className='mb-12 mt-4 w-full text-[#E2107B] sm:mb-32'>
                    <svg
                      className='w-full'
                      fill='none'
                      height={15}
                      width={1177}
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                        fill='currentColor'
                      />
                      <circle
                        cx='843.128'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='916.059'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='979.06'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='847.995'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='920.926'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='983.927'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='852.862'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='925.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='988.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <path
                        d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                  </div>
                  <section
                    className='flex flex-col gap-12 sm:gap-16'
                    id='who_we_are'>
                    <h2 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl sm:max-w-[936px]'>
                      Liquid Staking
                    </h2>
                    <figure className='flex flex-col items-center gap-4 sm:flex-row sm:gap-12'>
                      <div className='ml-auto mr-auto flex w-[272px]  items-center gap-8'>
                        <img
                          alt=''
                          loading='lazy'
                          width={108}
                          height={1}
                          decoding='async'
                          data-nimg={1}
                          srcSet='https://europe1.discourse-cdn.com/business20/uploads/aave/original/1X/551545d40f1dd9fd3d7a500022e3f492af23ea77.png'
                          src='https://europe1.discourse-cdn.com/business20/uploads/aave/original/1X/551545d40f1dd9fd3d7a500022e3f492af23ea77.png'
                          style={{ color: "transparent" }}
                        />
                        <img
                          alt='Blur NFT Marketplace'
                          loading='lazy'
                          width={106}
                          height={1}
                          decoding='async'
                          data-nimg={1}
                          srcSet='https://europe1.discourse-cdn.com/business20/uploads/lido/original/1X/5d951653caa6c4927d213859d314e5d2c933bdd8.png'
                          src='https://europe1.discourse-cdn.com/business20/uploads/lido/original/1X/5d951653caa6c4927d213859d314e5d2c933bdd8.png'
                          style={{ color: "transparent" }}
                        />
                      </div>
                      <div className='h-full w-[1px] bg-[#E2107B]' />
                      <figcaption className='typography-brand-heading-3 max-w-prose flex-[3] text-gray-200'>
                        Through BNB Liquid Staking, L2reum facilitates the
                        staking of your BNB tokens using carefully chosen
                        validator nodes. Simultaneously, the protocol generates
                        new yBNB tokens, which are BEP-20 reward-earning tokens
                        directly sent to your wallet. These tokens operate
                        through a daily rebasing mechanism, ensuring that
                        staking rewards are distributed to your wallet
                        consistently. In essence, your yBNB balance in your
                        wallet will increment daily to mirror the accruing
                        staking rewards.
                      </figcaption>
                    </figure>
                    <figure className='flex flex-col items-center gap-4 sm:flex-row sm:gap-12'>
                      <div className='ml-auto mr-auto flex w-[272px] flex-col items-center gap-8'>
                        <div className='flex gap-12'>
                          <img
                            alt='Massachusetts Institute of Technology (MIT)'
                            loading='lazy'
                            width={72}
                            height={1}
                            decoding='async'
                            data-nimg={1}
                            srcSet='https://europe1.discourse-cdn.com/business20/uploads/centrifuge1/original/1X/f94f5839003944d8d8e646b0bc39bd2521c3d410.svg'
                            src='https://europe1.discourse-cdn.com/business20/uploads/centrifuge1/original/1X/f94f5839003944d8d8e646b0bc39bd2521c3d410.svg'
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt='Yale University'
                            loading='lazy'
                            width={88}
                            height={1}
                            decoding='async'
                            data-nimg={1}
                            srcSet='https://global.discourse-cdn.com/standard11/uploads/staderlabs/original/1X/ed5db537e73264458a32d1c4f7523cfc8ccb290c.svg'
                            src='https://global.discourse-cdn.com/standard11/uploads/staderlabs/original/1X/ed5db537e73264458a32d1c4f7523cfc8ccb290c.svg'
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className='flex gap-12'>
                          <img
                            alt='Nanyang Technological University (NYU)'
                            loading='lazy'
                            width={88}
                            height={1}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/bnb1.svg'
                            src='images/bnb1.svg'
                            style={{ color: "transparent" }}
                          />
                          <img
                            alt='Seoul National University (SNU)'
                            loading='lazy'
                            width={56}
                            height={1}
                            decoding='async'
                            data-nimg={1}
                            srcSet='images/EthIconCard.ed8495d0.svg'
                            src='images/EthIconCard.ed8495d0.svg'
                            style={{ color: "transparent" }}
                          />
                        </div>
                      </div>
                      <div className='h-full w-[1px] bg-[#E2107B]' />
                      <figcaption className='typography-brand-heading-3 max-w-prose flex-[3] text-gray-200'>
                        L2reum integrated 2 Liquid Staking Platforms and
                        validators on the BNB Chain.
                        <ul>
                          <li>Stader</li>
                          <li>Ankr Liqudi Staking</li>
                        </ul>
                      </figcaption>
                    </figure>
                  </section>
                  <div className='mb-12 mt-12 w-full text-[#E2107B] sm:mb-32 sm:mt-32'>
                    <svg
                      className='w-full'
                      fill='none'
                      height={15}
                      width={1177}
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                        fill='currentColor'
                      />
                      <circle
                        cx='843.128'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='916.059'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='979.06'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='847.995'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='920.926'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='983.927'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='852.862'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='925.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='988.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <path
                        d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                  </div>

                  <section id='airdrop'>
                    <h2 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl sm:max-w-[936px] text-center m-auto'>
                      Airdrop timeline
                    </h2>
                    <h3 className='typography-brand-heading-3 my-8 mt-8 max-w-prose text-gray-300 sm:mt-6 text-center '>
                      The L2reum Community Airdrop is split between Early Access
                      Members (50%) and Developers (50%).
                    </h3>
                    <p className='typography-brand-body-l my-8 mt-8 max-w-prose text-gray-100 sm:mt-6'>
                      The Early Access airdrop is now live. You can earn airdrop
                      points by bridging to L2reum and inviting friends. Airdrop
                      points can be redeemed in May.
                    </p>
                    <img
                      alt='Early Access Airdrop timeline'
                      loading='lazy'
                      width={1176}
                      height={184}
                      decoding='async'
                      data-nimg={1}
                      className='my-8 ml-auto mr-auto hidden max-h-[432px] w-full sm:my-16 sm:block'
                      srcSet='https://blast.io/_next/image?url=%2Fimages%2Fblast-timeline-about.png&w=1200&q=75'
                      src='https://blast.io/_next/image?url=%2Fimages%2Fblast-timeline-about.png&w=3840&q=75'
                      style={{}}
                    />
                    <img
                      alt='Early Access Airdrop timeline'
                      loading='lazy'
                      width={323}
                      height={166}
                      decoding='async'
                      data-nimg={1}
                      className='my-8 ml-auto mr-auto block w-full max-w-md sm:hidden'
                      srcSet='https://blast.io/_next/image?url=%2Fimages%2Fblast-timeline-abstract.png&w=384&q=75'
                      src='https://blast.io/_next/image?url=%2Fimages%2Fblast-timeline-abstract.png&w=384&q=75'
                      style={{ color: "transparent" }}
                    />
                    <h3 className='typography-brand-heading-3 my-8 mt-8 max-w-prose text-gray-300 sm:mt-6'>
                      The developer airdrop goes live in January when the L2reum
                      Testnet launches. Learn more in our{" "}
                      <a
                        className='interactive-text text-white'
                        href='/en/devs'>
                        developer docs.
                      </a>
                    </h3>
                  </section>
                  <div>
                    <div className='ml-auto mr-auto flex flex-col-reverse items-center sm:flex-row sm:gap-12'>
                      <img
                        alt='alt_illustration-community'
                        loading='lazy'
                        width={300}
                        height={300}
                        decoding='async'
                        data-nimg={1}
                        className='aspect-square w-[300px] max-w-full invert'
                        srcSet='https://blast.io/_next/image?url=%2Fimages%2Fillustration-community.png&w=384&q=75'
                        src='https://blast.io/_next/image?url=%2Fimages%2Fillustration-community.png&w=384&q=75'
                        style={{ color: "transparent" }}
                      />
                      <div>
                        <h3 className='typography-brand-heading-3-caps text-left text-white xs:text-center sm:text-left'>
                          Join the Community
                        </h3>
                        <p className='typography-brand-heading-3 mb-6 mt-3 max-w-3xl text-left text-gray-300 xs:text-center sm:mt-6 sm:text-left'>
                          In the meantime, join our developer community on
                          Discord to learn more about L2reum (also so you don't
                          miss the airdrop).
                        </p>

                        <a
                          className='group mb-4 ml-auto mr-auto hidden  w-full max-w-md sm:m-0 sm:block sm:max-w-xs'
                          href='/'>
                          <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                            <button
                              tabIndex={-1}
                              className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                              Join L2reum Discord
                            </button>
                          </div>
                        </a>
                      </div>
                    </div>
                    <a
                      className='group ml-auto mr-auto mt-6 block w-full max-w-md sm:hidden'
                      href='/'
                      target='_blank'>
                      <div className='transition-[filter]'>
                        <button
                          tabIndex={-1}
                          className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                          Join L2reum Discord
                        </button>
                      </div>
                    </a>
                  </div>
                  <div className='mb-12 mt-12 block w-full text-[#33144A] sm:hidden'>
                    <svg
                      className='w-full'
                      fill='none'
                      height={15}
                      width={1177}
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                        fill='currentColor'
                      />
                      <circle
                        cx='843.128'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='916.059'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='979.06'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='847.995'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='920.926'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='983.927'
                        cy='11.374'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='852.862'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='925.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <circle
                        cx='988.794'
                        cy='11.37'
                        fill='currentColor'
                        r='1.46'
                      />
                      <path
                        d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                  </div>

                  <article className='relative mb-14 ml-auto mr-auto flex w-full max-w-screen-sm flex-col items-center rounded-lg sm:mt-32 sm:bg-[#33144A] sm:p-6'>
                    <h2 className='typography-brand-heading-2 text-center text-white'>
                      Have an Early Access invite?
                    </h2>

                    <Link
                      className='group mt-8 w-full xs:max-w-[264px] block'
                      to='/airdrop'
                      target='_blank'>
                      <div className='transition-[filter]'>
                        <button className='disabled:cursor-not-allowed disabled:bg-camo-300 text-sm font-normal disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-4 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                          Claim your airdrop
                        </button>
                      </div>
                    </Link>

                    <div className='absolute top-[-32px] hidden w-full text-[#33144A] sm:bottom-[100%] sm:block'>
                      <svg
                        className='max-w-full'
                        fill='none'
                        height={65}
                        viewBox='0 0 1024 65'
                        width={1024}
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M785.052 16.7089L800.381 1.51897L856.286 1.51898L870.262 12.9115L884.238 12.9115L884.689 1.51898L902.103 1.51898L902.272 8.10132L925.716 8.10133L925.716 1.51898L954.344 1.51899L1014 1.51899C1019.52 1.51899 1024 5.99615 1024 11.519L1024 65L0 64.9999L0.258424 51.8312C0.365297 46.3859 4.81012 42.0274 10.2565 42.0274L282.229 42.0275L350.758 -5.88567e-05L610.9 -3.61143e-05L625.778 16.7089L785.052 16.7089Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                    <div className='absolute bottom-[-28px] hidden w-full text-[#33144A] sm:bottom-[-42px] sm:block'>
                      <svg
                        className='max-w-full'
                        fill='none'
                        height={55}
                        viewBox='0 0 1024 55'
                        width={1024}
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M357.918 40.3596L342.611 55L313.797 55L299.841 44.0197H285.884L285.434 55H268.044L267.876 48.6558L127.41 48.6558V55H98.8213L9.99998 54.9999C4.47714 54.9999 0 50.5227 0 44.9999L0 0L1024 0V1.56473C1024 7.08757 1019.52 11.5647 1014 11.5647L849.432 11.5647L781 54.2678L589.776 54.2678L574.919 40.3596L357.918 40.3596Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
          <div className='bg-[#33144A] duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity' />
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default About;
