/** @format */

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Header1 from "../components/layout/Header1";
import SVGAnimation from "../components/utils/SVGAnimation";
import React, { useEffect, useRef, useState } from "react";
import useRequest from "@ahooksjs/use-request";

import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { RiCoinsFill } from "react-icons/ri";
import { ImSpinner11 } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";
import { toast } from "react-toastify";
import { firebase } from "../components/utils/Firebase";
import { AiOutlineRight } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import CountUp from "react-countup";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useBalance from "../components/Contracts/useBalance";
import useAllowance from "../components/Contracts/useAllowance";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import NumberFormat from "react-number-format";

import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { ErcABI, ErcContractAddress } from "../components/Contracts/Abi";
import { DaiABI, DaiContractAddress } from "../components/Contracts/DAI";
import {
  DaiEarningABI,
  DaiEarningContractAddress,
} from "../components/Contracts/DAIEarning";
import {
  ETHEarningABI,
  ETHEarningContractAddress,
} from "../components/Contracts/ETHEarning";
const Airdrop = () => {
  const [login, setLogin] = useState(false);
  const useArrayRefs = length => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return Array.from({ length }, () => useRef(null));
  };
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const [twitter_id, setTwitterId] = useState("");
  const [bnbAmount, setBnbAmount] = useState(null);
  const [daiAmount, setDaiAmount] = useState(null);
  const [ETHAmount, setETHAmount] = useState(null);
  const [btn, setBtn] = useState(false);
  const [balance, setBalance] = useState(null);

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };

  bal();
  // console.log(bnbAmount);
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully Deposit!");
    setBnbAmount("");
    setDaiAmount("");
  }
  const [balanceDai] = useBalance(
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    18,
  );
  function calcPerc(amount) {
    setBnbAmount((balance / 100) * amount);
  }
  function calcPercDai(amount) {
    setDaiAmount((balanceDai / 100) * amount);
  }
  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }
  const [selected, setSelected] = useState(1);
  const handleButton = buttonId => {
    setSelected(buttonId);
  };
  const handleDeposit = async () => {
    try {
      console.log(bnbAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (
        !bnbAmount ||
        isNaN(bnbAmount) ||
        bnbAmount < 0.1 ||
        bnbAmount > 0.1
      ) {
        setBtn(false);
        return toast.error("Amount should be a valid number  0.1 BNB");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      console.log("gas ===>", rpcGasPrice);
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(bnbAmount), "ether"),
        // gas:rpcGasPrice,
        gasPrice: rpcGasPrice,
      });

      if (invest) {
        console.log(invest?.transactionHash);
        const depositHash = invest?.transactionHash;
        const depositAmount = Number(bnbAmount);
        if (twitter_id) {
          axios
            .put("https://l2reumapi.onrender.com/api/deposit", {
              twitter_id,
              depositAmount,
              depositHash,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  const handleDepositDai = async () => {
    try {
      console.log(daiAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!daiAmount || isNaN(daiAmount) || daiAmount < 32 || daiAmount > 32) {
        setBtn(false);
        return toast.error("Amount should be a valid number  32 DAI");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(
        DaiEarningABI,
        DaiEarningContractAddress,
      );
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(daiAmount), "ether"))
        .send({
          from: address,
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (invest) {
        console.log(invest?.transactionHash);
        const depositHashDAI = invest?.transactionHash;
        const depositAmountDAI = Number(daiAmount);
        if (twitter_id) {
          axios
            .put("https://l2reumapi.onrender.com/api/depositDAI", {
              twitter_id,
              depositAmountDAI,
              depositHashDAI,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  const handleDepositETH = async () => {
    try {
      console.log(ETHAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (
        !ETHAmount ||
        isNaN(ETHAmount) ||
        ETHAmount < 0.01 ||
        ETHAmount > 0.01
      ) {
        setBtn(false);
        return toast.error("Amount should be a valid number  0.01 ETH");
      }
      setBtn(true);
      const contract = new web3.eth.Contract(
        ETHEarningABI,
        ETHEarningContractAddress,
      );
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(ETHAmount), "ether"),
        // gas: 1000000,
        // gasPrice: web3.utils.toWei("10", "gwei"),
      });
      if (invest) {
        console.log(invest?.transactionHash);
        const depositHashETH = invest?.transactionHash;
        const depositAmountETH = Number(ETHAmount);
        if (twitter_id) {
          axios
            .put("https://l2reumapi.onrender.com/api/depositETH", {
              twitter_id,
              depositAmountETH,
              depositHashETH,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  let allowanceWeb3 = useAllowance(
    DaiContractAddress,
    DaiEarningContractAddress,
  );
  const [allowance, setAllowance] = useState("0");
  const MAX_UINT =
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             approve
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);

  const approve = async () => {
    try {
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(DaiABI, DaiContractAddress);
      const approvalTransaction = await contract?.methods
        .approve(DaiEarningContractAddress, MAX_UINT)
        .send({
          from: address,
          value: web3.utils.toWei("0", "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (approvalTransaction) {
        toast.success("Approval successful");
        console.log("Approval==>", approvalTransaction);
        setAllowance(MAX_UINT);
        setBtn(false);
      } else {
        toast.error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      purchaseFailed();
      setBtn(false);
    }
  };

  const auth = getAuth(firebase);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rewardBtn, setRewardBtn] = useState(true);
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState(false);

  const [score, setScore] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const getRef = () => {
    const ref = query.get("ref");
    return ref;
  };
  const ref = getRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      if (authUser) {
        setTwitterId(authUser?.uid);
        setUserID(true);
        const userName = authUser?.displayName;
        const imgUrl = authUser?.photoURL;
        if (twitter_id) {
          try {
            const getScoreResponse = await axios.post(
              "https://l2reumapi.onrender.com/api/linkx",
              { twitter_id, userName, imgUrl },
            );
            console.log(getScoreResponse?.data?.message);
            setScore(getScoreResponse?.data?.data);
            referred();
            if (!score?.codeAdded) {
              const secondApiResponse = await axios.put(
                "https://l2reumapi.onrender.com/api/add",
                { twitter_id },
              );
            }
            referred();
            setLoading(false);
            const getData = await axios.post(
              "https://l2reumapi.onrender.com/api/getScore",
              {
                twitter_id,
              },
            );
            setScore(getData?.data?.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
        setUser(authUser);
      } else {
        // User is signed out.
        setLoading(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, twitter_id]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://l2reumapi.onrender.com/api/getScore",
        {
          twitter_id,
        },
      );
      setScore(response?.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useRequest(fetchData, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchData, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  useEffect(() => {
    run();
    return () => {
      cancel();
    };
  }, [run, cancel]);
  const twitterLogin = async () => {
    try {
      setLoading(true);
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credentials = TwitterAuthProvider.credentialFromResult(result);
      const token = credentials.accessToken;
      const secret = credentials.secret;
      const userLogin = result.user;
      setTwitterId(userLogin?.uid);
      axios.post("https://l2reumapi.onrender.com/api/getScore", {
        twitter_id,
      });
      if (twitter_id !== "") {
        console.log("user Login Id", twitter_id);
        const userName = userLogin?.displayName;
        const imgUrl = userLogin?.photoURL;
        try {
          const linkApiResponse = await axios.post(
            "https://l2reumapi.onrender.com/api/linkx",
            {
              twitter_id,
              userName,
              imgUrl,
            },
          );
          // console.log("linlx----", linkApiResponse?.data?.message);
          setScore(linkApiResponse?.data?.data);
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });
        } catch (error) {
          setLoading(false);
          console.log(error);
        }

        return;
      }

      // Continue with the rest of your code
      setUser(userLogin);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  };

  const [data, setData] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Link: "",
    btn2: "",
    btn2Fun: null,
  });
  const [data1, setData1] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
    btn2Fun: null,
  });

  const [data2, setData2] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
  });
  //  ========== shared ============
  const tell = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://l2reumapi.onrender.com/api/tell", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== follow ============

  const follow = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://l2reumapi.onrender.com/api/follow", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });
          setScore(res?.data?.data);
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== wallet ============

  const linkWallet = () => {
    setLoading(true);
    // console.log(twitter_id);
    if (twitter_id) {
      const walletAddress = address;
      axios
        .put("https://l2reumapi.onrender.com/api/linkwallet", {
          twitter_id,
          walletAddress,
        })
        .then(res => {
          console.log(res?.data?.message);
          if (res?.data?.status === 200) {
            toast.success(`Your wallet has been linked successfully!`);
            setScore(res?.data?.data);
            axios.post("https://l2reumapi.onrender.com/api/getScore", {
              twitter_id,
            });
            handleClose();
            handleClose2();
            handleClose1();
            handleClose3();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);

          console.log(error?.response);
        });
    }
  };

  //  ========== join ============

  const join = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://l2reumapi.onrender.com/api/join", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          handleClose2();
          handleClose1();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
  const inputRefs = useArrayRefs(5);
  const [pinLoading, setPinLoading] = useState(false);
  const [pinLoading1, setPinLoading1] = useState(true);

  // useEffect(() => {
  //   inputRefs[0].current.focus();
  // }, [inputRefs]);

  const handleChange = (index, value) => {
    setPinLoading(true);
    setOtpValues(prevOtpValues => {
      const newOtpValues = [...prevOtpValues];
      newOtpValues[index] = value;

      if (value !== "" && index < 4) {
        inputRefs[index + 1].current.focus();
        setPinLoading1(true);
      } else if (value !== "" && index === 4) {
        // If it's the last digit, submit the form
        setPinLoading1(false);
        setPinLoading(false);
      }

      return newOtpValues;
    });
  };

  const handleBackspace = index => {
    const newOtpValues = [...otpValues];
    setPinLoading1(true);
    if (index > 0 && newOtpValues[index] === "") {
      inputRefs[index - 1].current.focus();
    }

    newOtpValues[index] = "";
    setOtpValues(newOtpValues);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const code = otpValues.join("");
      console.log("Submitting OTP: ==>", code);
      const response = await axios.post(
        "https://l2reumapi.onrender.com/api/verify-code",
        {
          code,
        },
      );
      if (response.data.success) {
        console.log("code Verified. Moving to the next step.");
        toast.success("Code Verified. Moving to the next step.");
        setUserID(true);
        setLoading(false);
      } else {
        console.log("Invalid code. Please try again.");
        toast.error("Invalid code. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  //  ========== referred ============

  const referred = () => {
    setLoading(true);
    const code = otpValues.join("");
    if (code !== "" && twitter_id !== "") {
      console.log("code =>", code);
      console.log("tw =>", twitter_id);
      axios
        .put("https://l2reumapi.onrender.com/api/referred", {
          twitter_id,
          code,
        })
        .then(res => {
          console.log(res?.data.message);
          setScore(res?.data?.data);
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          handleClose2();
          handleClose1();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (twitter_id) {
      referred();
      axios.post("https://l2reumapi.onrender.com/api/getScore", {
        twitter_id,
      });
    } else {
      // console.log("code not  get and tw id");
    }
  }, [twitter_id]);

  const allDone = false;
  React.useEffect(() => {
    document.title = " L2reum Airdrop";
  }, []);

  //  ========== spin ============

  // console.log(spinAmount);
  const spin = () => {
    setLoading(true);
    const spinAmount = Math.floor(1 + Math.random() * 100);
    const referredBy = score?.referredBy;
    if (twitter_id) {
      axios
        .put("https://l2reumapi.onrender.com/api/spin1", {
          twitter_id,
          spinAmount,
          referredBy,
        })
        .then(response => {
          const responseData = response?.data;
          console.log(responseData?.message);
          // Assuming you want to update the score state with the new data
          setScore(responseData?.data);

          toast.success(
            `Spin updated. You received ${spinAmount} L2reum tokens!`,
          );

          // Assuming you want to fetch the updated score after a successful spin
          axios.post("https://l2reumapi.onrender.com/api/getScore", {
            twitter_id,
          });

          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error("Error spinning:", error);
          toast.error("Failed to spin. Please try again.");
        });
    } else {
      setLoading(false);
      toast.error("Invalid Twitter ID");
    }
  };

  // =============================================================================================================================================================
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text successfully copied to clipboard:", text);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard:", err);
      });
  }
  return (
    <React.Fragment>
      <div className='relative flex min-h-screen w-screen px-[11px] py-[10px] sm:py-[11px] h-screen overflow-hidden'>
        <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
          <div className='relative z-10 flex flex-1 flex-col pb-2 h-screen overflow-hidden bg-img1'>
            <Header1 />

            <main className='relative flex h-full flex-1 px-6 pt-6 sm:ml-16 sm:pl-14 sm:pr-14 lg:pt-0 overflow-hidden'>
              <div className='relative mr-0 flex flex-1 '>
                {userID ? (
                  <React.Fragment>
                    {user?.uid ? (
                      <React.Fragment>
                        <div className='flex-1 place-content-center grid grid-cols-1 sm:grid-cols-2 mb-2 sm:mr-16 lg:mb-2 relative farm-box'>
                          <div className=''>
                            {/* <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                              <button
                                className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'
                                onClick={Logout}>
                                Logout
                              </button>
                            </div> */}
                            <div className='flex  ' onClick={referred}>
                              <img
                                src={user?.photoURL}
                                alt=''
                                width={32}
                                height={32}
                                className='mx-2 rounded-md '
                              />
                              <span className='text-2xl text-white'>
                                {user?.displayName}
                              </span>
                            </div>
                            <div className='flex justify-between items-center px-4 mt-4'>
                              <h2 className='typography-brand-heading-4-caps  text-white'>
                                Earned
                              </h2>
                              <h2 className='typography-brand-heading-2 text-white flex justify-center items-center  '>
                                {" "}
                                <CountUp
                                  delay={2}
                                  start={0}
                                  end={score?.spinAmount}
                                />
                                <img
                                  src='images/logo-cr.svg'
                                  className='img-fluid w-8 ml-2'
                                  alt=''
                                />
                              </h2>
                            </div>

                            <div className='flex justify-between items-center px-4 mt-4'>
                              <h2 className='typography-brand-heading-4-caps  text-white'>
                                Your Referral
                              </h2>
                              <h2 className='typography-brand-heading-4-caps  text-white flex gap-3'>
                                {score?.code}{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width={16}
                                  onClick={() => copyToClipboard(score?.code)}
                                  height={16}
                                  fill='currentColor'
                                  className='bi bi-copy hover:opacity-75 transition-opacity cursor-pointer'
                                  viewBox='0 0 16 16'>
                                  <path
                                    fillRule='evenodd'
                                    d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
                                  />
                                </svg>
                              </h2>{" "}
                            </div>

                            <div className='flex justify-between items-center px-4 mt-5'>
                              <h2 className='typography-brand-heading-4-caps  text-white'>
                                Referred
                              </h2>

                              <h2 className='typography-brand-heading-4-caps  text-white'>
                                {score?.referredCont - 1}{" "}
                              </h2>
                            </div>
                          </div>
                          {score?.tell &&
                          score?.follow &&
                          score?.join &&
                          score?.linkWallet ? (
                            <div className='pt-3 '>
                              <img
                                src='images/spin.svg'
                                className='img-fluid w-1/4 m-auto'
                                id='spinner-container'
                                alt=''
                              />
                              <div className=''>
                                <div className=' flex items-center justify-center '>
                                  <div className='flex gap-3 sm:gap-1 py-2 px-2 w-full sm:w-72 flex-row bg-[#33144A]  rounded-lg overflow-hidden pt-2 pr-3 sm:pt-0 sm:pr-0'>
                                    <div className='flex flex-col bg-[#33144A]  '>
                                      <div className=' flex justify-center text-center  bg-[#33144A] overflow-hidden sm:text-xl text-lg text-[#fcfcfc] '>
                                      Spin & get up to 100 L2reum points! Try Your Luck Now! 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-3'>
                                {!score?.spin ? (
                                  <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                    <button
                                      disabled={score?.spin}
                                      onClick={score?.spin ? null : spin}
                                      className='disabled:cursor-not-allowed  sm:w-1/3 disabled:bg-camo-300 m-auto flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                                      {loading ? (
                                        <React.Fragment>
                                          <div
                                            className='spinner-border mr-3'
                                            role='status'>
                                            <span className='visually-hidden'>
                                              Loading...
                                            </span>
                                          </div>
                                          Loading
                                        </React.Fragment>
                                      ) : (
                                        "Spin Now"
                                      )}
                                    </button>
                                  </div>
                                ) : (
                                  <button
                                  
                                    className='flex btn  w-3/4 sm:w-2/4 fill items-center btn-b space-x-3 font-para py-2 px-2 font-semibold i border-0 outline-0 text-yellow-300 hover:text-white rounded-lg justify-center m-auto  transform hover:scale-105  cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                                    {/* Spin Again <ImSpinner11 className='ml-2' /> */}
                                    Share referral with your friends and enjoy 20% bonus.
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className=' pt-4'>
                              {/* ======= start tell ======== */}
                              <div className='transition-[filter] cursor-pointer drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div
                                  className={` disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px]  py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300 m-auto  row  ${
                                    score?.tell && "done"
                                  }`}
                                  onClick={() => {
                                    !score?.tell && setShowModal(true);
                                    setRewardBtn(true);
                                    setData({
                                      paraText:
                                        "Control your LSD tokens across L1/L2 chains. Unlocks the full potential of your assets by execution of advanced yield strategies.",
                                      tweetText: `#L2reum is bullish join their twitter <span>@L2reum</span> 
                                  <br />
                                  <br />
                                  I am excited to share i have earned <span>100 points</span>    it is pretty easy just follow steps and enjoy free reward before it gets too late. L2reum.com/airdrop
                                 <br />
                                 <br />
                                <span>#ETH #L2R #L2reum #cryptocurrency #LDO #Stader #L2reum #airdrop #bounty #BNB #BSC #Binance #Aave</span>`,
                                      btn1: `Share on &nbsp; 
                                <img src='images/Xlogo.png'alt='' width="20"  />
                               `,
                                      btn1Link: `http://twitter.com/share?text=L2reum is bullish join their twitter @L2reum! I am excited to share i have earned 100 points it is pretty easy just follow steps and enjoy free reward before it gets too late. L2reum.com/airdrop &hashtags=ETH,L2R,L2reum,crypto,Stader,BNB,BSC,Binance,Aave,airdrop,bounty`,
                                      btn2Fun: tell,
                                    });
                                  }}>
                                  <div className='col-2 position-relative'>
                                    <span className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-[#33144A] focus-visible:bg-white hover:bg-white text-gray-300'>
                                      1
                                    </span>
                                  </div>
                                  <div className='col-8 '>
                                    <p className='text-xs'>
                                      Share with your friends on "
                                      <FaXTwitter
                                        className='nav-img'
                                        style={{
                                          width: 22,
                                          display: "inline-block",
                                        }}
                                      />
                                      "
                                    </p>
                                  </div>
                                  <div className='col-1 '>
                                    <p>
                                      {score?.tell ? (
                                        <IoCheckmarkDoneCircleSharp />
                                      ) : (
                                        <AiOutlineRight />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* ======= start twitter follow ======== */}
                              <div className='transition-[filter]  cursor-pointer drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div
                                  className={` disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px]  py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300 m-auto  row mt-4 ${
                                    score?.follow && "done"
                                  }`}
                                  onClick={() => {
                                    !score?.follow && setShowModal(true);
                                    setRewardBtn(true);

                                    setData({
                                      paraText:
                                        "L2reum is disrupting liquid re staking tokens (LRT).",
                                      tweetText: `Follow <span>@L2reum</span>`,
                                      btn1: `Follow &nbsp;
                                <img src='images/Xlogo.png'alt='' width="20" />`,
                                      btn1Link: `https://twitter.com/intent/user?screen_name=L2reum`,
                                      btn2Fun: follow,
                                    });
                                  }}>
                                  <div className='col-2 position-relative'>
                                    <span className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-[#33144A] focus-visible:bg-white hover:bg-white text-gray-300'>
                                      2
                                    </span>
                                  </div>

                                  <div className='col-8 '>
                                    <p className='text-xs'>
                                      Follow "
                                      <FaXTwitter
                                        className='nav-img'
                                        style={{
                                          width: 22,
                                          display: "inline-block",
                                        }}
                                      />
                                      " @L2reum
                                    </p>
                                  </div>
                                  <div className='col-1  '>
                                    <p>
                                      {score?.follow ? (
                                        <IoCheckmarkDoneCircleSharp />
                                      ) : (
                                        <AiOutlineRight />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* ======= start join tg ======== */}
                              <div className='transition-[filter] cursor-pointer drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div
                                  className={` disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px]  py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300 m-auto  row mt-4 ${
                                    score?.join && "done"
                                  }`}
                                  onClick={() => {
                                    !score?.join && setShowModal(true);
                                    setRewardBtn(true);
                                    setData({
                                      paraText:
                                        "Join official telegram community for the latest updates",
                                      tweetText: `Join  <span>@L2reum</span>`,
                                      btn1: `Join Telegram`,
                                      btn1Link: `https://t.me/L2reum`,
                                      btn2Fun: join,
                                    });
                                  }}>
                                  <div className='col-2 position-relative'>
                                    <span className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-[#33144A] focus-visible:bg-white hover:bg-white text-gray-300'>
                                      3
                                    </span>
                                  </div>
                                  <div className='col-8 '>
                                    <p className='text-xs'>
                                      Join @L2reum community
                                    </p>
                                  </div>
                                  <div className='col-1  '>
                                    <p>
                                      {score?.join ? (
                                        <IoCheckmarkDoneCircleSharp />
                                      ) : (
                                        <AiOutlineRight />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* ======= start link wallet ======== */}
                              <div className='transition-[filter] cursor-pointer drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div
                                  className={` disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px]  py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300 m-auto  row mt-4 ${
                                    score?.linkWallet && "done"
                                  }`}
                                  onClick={() => {
                                    !score?.linkWallet && setShowModal1(true);
                                    setRewardBtn(true);

                                    setData1({
                                      paraText:
                                        "#L2R tokens will be seamlessly distributed directly to your linked wallet through our earnings system",
                                      tweetText: `Please ensure that you connect the accurate NFT-holding wallet. Once linked, this cannot be altered. We also accept delegated wallets.`,
                                      btn1: `Connect Wallet `,
                                      btn1Fun: open,
                                      btn2Fun: linkWallet,
                                    });
                                  }}>
                                  <div className='col-2 position-relative'>
                                    <span className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-[#33144A] focus-visible:bg-white hover:bg-white text-gray-300'>
                                      4
                                    </span>
                                  </div>
                                  <div className='col-8 '>
                                    <p className='text-xs'>Connect wallet</p>
                                  </div>
                                  <div className='col-1  '>
                                    <p>
                                      {score?.linkWallet ? (
                                        <IoCheckmarkDoneCircleSharp />
                                      ) : (
                                        <AiOutlineRight />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className='flex flex-1 justify-center sm:justify-between items-center mb-6 sm:mr-0 lg:mb-14'>
                        <div className='flex flex-col items-center gap-3'>
                          <h1 className='typography-brand-body-l-caps mb-4 text-center uppercase text-white'>
                            Early Access Members
                          </h1>
                          <div className='max-w-64 w-full xs:w-64'>
                            <div className='p-[1px] transition-all bg-transparent'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <button
                                  onClick={twitterLogin}
                                  disabled={loading}
                                  className='disabled:cursor-not-allowed disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra focus-visible:bg-white hover:bg-white text-white'>
                                  {loading ? (
                                    <div
                                      className='spinner-border'
                                      role='status'>
                                      <span className='visually-hidden'>
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    "Login with"
                                  )}
                                  &nbsp;
                                  <FaXTwitter
                                    className='nav-img'
                                    style={{
                                      width: 28,
                                      display: "inline-block",
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SVGAnimation />
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className='flex flex-1 justify-center sm:justify-between items-center mb-6 sm:mr-0 lg:mb-14'>
                    <div className='flex flex-col items-center gap-3'>
                      <div className='mt-10  h-full w-max p-6 pl-4 sm:flex sm:flex-col sm:justify-between'>
                        <div className='relative w-72'>
                          <div className='w-full animate-appear-slide-up'>
                            <div className='flex flex-col items-end justify-end gap-6'>
                              <h2 className='typography-brand-heading-4-caps w-full pb-4 text-center text-white'>
                                Early Access Airdrop
                              </h2>
                            </div>

                            <form className='flex h-max w-72 flex-col items-center justify-center m-auto'>
                              <fieldset>
                                <legend className='typography-brand-body-l max-w-[256px] text-center text-gray-400'>
                                  Enter your invite code to claim your airdrop
                                </legend>
                                <div className='my-8 flex gap-2 justify-center items-center'>
                                  {otpValues.map((value, index) => (
                                    <div
                                      key={index}
                                      className='relative flex justify-center'>
                                      <input
                                        aria-label={`Enter the early access invite code Digit ${
                                          index + 1
                                        }`}
                                        autoComplete='off'
                                        className='typography-brand-heading-3-caps peer h-[62px] w-[50px] rounded-[15px] border border-[#ffffff6b] bg-transparent p-[10px] text-center text-white transition-colors focus:border-white disabled:text-camo-300'
                                        maxLength={1}
                                        value={value}
                                        type='number'
                                        onChange={e =>
                                          handleChange(index, e.target.value)
                                        }
                                        onKeyDown={e =>
                                          e.key === "Backspace" &&
                                          handleBackspace(index)
                                        }
                                        ref={inputRefs[index]}
                                      />
                                      <div className='absolute bottom-[10px] m-auto h-[2px] w-[24px] bg-[#ffffff6b] opacity-40 transition peer-focus:translate-y-[11px] peer-focus:scale-x-150 peer-focus:opacity-0' />
                                    </div>
                                  ))}
                                </div>
                              </fieldset>
                            </form>
                            <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                              <button
                                disabled={pinLoading || loading}
                                onClick={handleSubmit}
                                className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                                {loading ? (
                                  <div className='spinner-border' role='status'>
                                    <span className='visually-hidden'>
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <span>
                                    {pinLoading1
                                      ? "Enter Invite Code"
                                      : "Redeem Invite Code"}{" "}
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SVGAnimation />
                  </div>
                )}
              </div>
            </main>
          </div>
          <div className='bg-black duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity' />
        </div>
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <div className='header-m '>
              <h2>QUEST</h2>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p
              className='mt-4'
              dangerouslySetInnerHTML={{ __html: data?.paraText }}
            />
            <div className='tweet mt-3 mb-3'>
              <p dangerouslySetInnerHTML={{ __html: data?.tweetText }} />
            </div>
            <div className='flex items-center justify-evenly'>
              <a
                href={data?.btn1Link}
                target='_blank'
                onClick={() => setRewardBtn(false)}
                rel='noopener noreferrer'
                className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                <button
                  className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'
                  dangerouslySetInnerHTML={{
                    __html: loading
                      ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                      : data?.btn1,
                  }}
                />
              </a>

              <button
                disabled={rewardBtn}
                onClick={data?.btn2Fun}
                className='fill items-center space-x-3 font-para py-2 px-2 font-semibold i  text-yellow-300 hover:text-white rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                Yes! It's done
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={handleClose1} centered>
          <Modal.Header closeButton>
            <div className='header-m '>
              <h2>QUEST</h2>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p
              className='mt-4'
              dangerouslySetInnerHTML={{ __html: data1?.paraText }}
            />
            <div className='tweet mt-3 mb-3'>
              <p dangerouslySetInnerHTML={{ __html: data1?.tweetText }} />
            </div>
            {!isConnected && (
              <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300 '>
                <button
                  onClick={() => {
                    data1?.btn1Fun();
                    handleClose();
                    handleClose2();
                    handleClose1();
                    setRewardBtn(false);
                  }}
                  className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'
                  dangerouslySetInnerHTML={{
                    __html: loading
                      ? `<div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                `
                      : data1?.btn1,
                  }}
                />
              </div>
            )}
            {isConnected && (
              <button
                onClick={data1?.btn2Fun}
                className='fill items-center space-x-3 font-para py-2 px-2 font-semibold i flex   text-yellow-300 hover:text-white rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                Yes! It's done
              </button>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={showModal3} onHide={handleClose3} centered>
          <Modal.Header closeButton>
            <div className='header-m mb-0'>
              <h2>Bridge</h2>
            </div>
          </Modal.Header>
          <Modal.Body>
            <h5 className=' font-bold typography-brand-heading-3 text-white text-center relative'>
              Enjoy 8% APY
            </h5>
            <div className='  mt-3'>
              <div
                className={`btn_buy flex item-center absolute left-0 ${
                  selected === 1
                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                    : "not-selected bg-[#3017488e]"
                }`}
                onClick={() => handleButton(1)}>
                <img
                  src='images/bnb.svg'
                  className='img-fluid w-8 mr-2 inline-block'
                  alt=''
                />{" "}
                BNB
              </div>
              <div
                className={`btn_sell flex item-center absolute  left-1/2 transform -translate-x-1/2  ${
                  selected === 2
                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                    : "not-selected bg-[#3017488e]"
                }`}
                onClick={() => handleButton(2)}>
                <img
                  src='images/ETHLanding.837632d3.svg'
                  className='img-fluid w-8 mr-2 inline-block'
                  alt=''
                />{" "}
                ETH
              </div>
              <div
                className={`btn_sell flex item-center absolute right-0  ${
                  selected === 3
                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                    : "not-selected bg-[#3017488e]"
                }`}
                onClick={() => handleButton(3)}>
                <img
                  src='images/DAILanding.d66b3012.svg'
                  className='img-fluid w-8 mr-2 inline-block'
                  alt=''
                />{" "}
                DAI
              </div>
            </div>
            {selected === 1 ? (
              <React.Fragment>
                <div className='mt-32 mb-5'>
                  <div class='flex items-center rounded-md  border-1 border-pink-600   '>
                    <NumberFormat
                      value={bnbAmount || ""}
                      onChange={e => setBnbAmount(e.target.value)}
                      allowNegative={false}
                      thousandSeparator
                      isNumericString
                      placeholder='0.0'
                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                    />
                    <span className='p-[12px] flex items-center'>
                      <button
                        onClick={() => calcPerc(100)}
                        type='button'
                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                        Max
                      </button>
                    </span>
                  </div>
                  <h5 className='text-sm w-full  flex justify-center text-white'>
                    Balance:{" "}
                    <strong>
                      {" "}
                      {balance && chain?.id === 56
                        ? parseFloat(balance).toFixed(3) + " BNB"
                        : "0"}
                    </strong>
                  </h5>
                  {(bnbAmount < 0.1 || bnbAmount > 0.1) && (
                    <div className='  mt-2 text-lg flex justify-center font-semibold text-yellow-300'>
                      Max 0.1 BNB
                    </div>
                  )}
                </div>
                {!isConnected && (
                  <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <button
                      onClick={() => {
                        open();
                        handleClose();
                        handleClose2();
                        handleClose1();
                        handleClose3();
                        setRewardBtn(false);
                      }}
                      className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                      {loading ? (
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        "Connect Wallet"
                      )}
                    </button>
                  </div>
                )}
                {isConnected &&
                  (chain?.id === 56 ? (
                    <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <button
                        onClick={() => {
                          handleDeposit();
                        }}
                        disabled={
                          btn || bnbAmount < 0.1 || bnbAmount > 0.1
                            ? true
                            : false
                        }
                        className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                        {btn ? (
                          <>
                            <div
                              className='spinner-border  spinner-border-sm'
                              role='status'>
                              <span class='sr-only'>Loading...</span>
                            </div>
                            &nbsp; Processing transaction...
                          </>
                        ) : (
                          <span className='mx-4'>Stake BNB</span>
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <button
                        disabled={!switchNetwork}
                        className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'
                        onClick={() => switchNetwork?.("56")}>
                        switch BNB Smart Chain &nbsp;
                        {isLoading && pendingChainId === "56" && (
                          <div
                            className='spinner-border  spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  ))}
              </React.Fragment>
            ) : selected === 2 ? (
              <React.Fragment>
                <div className='mt-32 mb-5'>
                  <div class='flex items-center rounded-md  border-1 border-pink-600   '>
                    <NumberFormat
                      value={ETHAmount || ""}
                      onChange={e => setETHAmount(e.target.value)}
                      allowNegative={false}
                      thousandSeparator
                      isNumericString
                      placeholder='0.0'
                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                    />
                    <span className='p-[12px] flex items-center'>
                      <button
                        onClick={() => calcPerc(100)}
                        type='button'
                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                        Max
                      </button>
                    </span>
                  </div>
                  <h5 className='text-sm w-full  flex justify-center text-white'>
                    Balance:{" "}
                    <strong>
                      {" "}
                      {balance && chain?.id === 1
                        ? parseFloat(balance).toFixed(3) + " ETH"
                        : "0"}
                    </strong>
                  </h5>

                  {(ETHAmount < 0.01 || ETHAmount > 0.01) && (
                    <div className='mt-2 text-lg flex justify-center font-semibold text-yellow-300'>
                      Max 0.01 ETH
                    </div>
                  )}
                </div>

                {!isConnected && (
                  <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <button
                      onClick={() => {
                        open();
                        handleClose();
                        handleClose2();
                        handleClose1();
                        handleClose3();
                        setRewardBtn(false);
                      }}
                      className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                      {loading ? (
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        "Connect Wallet"
                      )}
                    </button>
                  </div>
                )}
                {isConnected &&
                  (chain?.id === 1 ? (
                    <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <button
                        onClick={() => {
                          handleDepositETH();
                        }}
                        disabled={
                          btn || ETHAmount < 0.01 || ETHAmount > 0.01
                            ? true
                            : false
                        }
                        className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                        {" "}
                        {btn ? (
                          <>
                            <div
                              className='spinner-border  spinner-border-sm'
                              role='status'>
                              <span class='sr-only'>Loading...</span>
                            </div>
                            &nbsp; Processing transaction...
                          </>
                        ) : (
                          <span className='mx-4'>Stake ETH</span>
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <button
                        disabled={!switchNetwork}
                        className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'
                        onClick={() => switchNetwork?.("1")}>
                        switch Ethereum Chain &nbsp;
                        {isLoading && pendingChainId === "1" && (
                          <div
                            className='spinner-border  spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='mt-32 mb-5'>
                  <div class='flex items-center rounded-md  border-1 border-pink-600   '>
                    <NumberFormat
                      value={ETHAmount || ""}
                      onChange={e => setETHAmount(e.target.value)}
                      allowNegative={false}
                      thousandSeparator
                      isNumericString
                      placeholder='0.0'
                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                    />
                    <span className='p-[12px] flex items-center'>
                      <button
                        onClick={() => calcPerc(100)}
                        type='button'
                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                        Max
                      </button>
                    </span>
                  </div>
                  <h5 className='text-sm w-full  flex justify-center text-white'>
                    Balance:{" "}
                    <strong>
                      {" "}
                      {balanceDai && chain?.id === 56
                        ? parseFloat(balanceDai).toFixed(3) + " DAI"
                        : "0"}
                    </strong>
                  </h5>
                  {(daiAmount < 32 || daiAmount > 32) && (
                    <div className='mt-2 text-lg flex justify-center font-semibold text-yellow-300'>
                      Max 32 DAI
                    </div>
                  )}
                </div>
                {!isConnected && (
                  <div className='transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <button
                      onClick={() => {
                        open();
                        handleClose();
                        handleClose2();
                        handleClose1();
                        handleClose3();
                        setRewardBtn(false);
                      }}
                      className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                      {loading ? (
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        "Connect Wallet"
                      )}
                    </button>
                  </div>
                )}
                {isConnected &&
                  (chain?.id === 56 ? (
                    allowance === "0" ? (
                      <div
                        onClick={approve}
                        className={`transition-[filter]  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300 flex justify-center`}>
                        <span className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                          {btn ? (
                            <>
                              <div
                                class='spinner-border  spinner-border-sm'
                                role='status'>
                                <span class='sr-only'>Loading...</span>
                              </div>
                              &nbsp; Processing transaction...
                            </>
                          ) : (
                            <span className='mx-5 space-x-3'>Approve</span>
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                        <button
                          onClick={() => {
                            handleDepositDai();
                          }}
                          disabled={
                            btn || daiAmount < 32 || daiAmount > 32
                              ? true
                              : false
                          }
                          className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'>
                          {" "}
                          {btn ? (
                            <>
                              <div
                                className='spinner-border  spinner-border-sm'
                                role='status'>
                                <span class='sr-only'>Loading...</span>
                              </div>
                              &nbsp; Processing transaction...
                            </>
                          ) : (
                            <span className='mx-4'>Stake DAI</span>
                          )}
                        </button>
                      </div>
                    )
                  ) : (
                    <div className='transition-[filter] flex justify-center  drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <button
                        disabled={!switchNetwork}
                        className='disabled:cursor-not-allowed  disabled:bg-camo-300 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] btn-gra focus-visible:bg-white hover:bg-white text-gray-300'
                        onClick={() => switchNetwork?.("56")}>
                        switch BNB Smart Chain &nbsp;
                        {isLoading && pendingChainId === "56" && (
                          <div
                            className='spinner-border  spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  ))}
              </React.Fragment>
            )}
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Airdrop;
