/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC0Qb-QxarS66iFCYbFh6RxG9YBXfoPJw8",
  authDomain: "l2reum.firebaseapp.com",
  projectId: "l2reum",
  storageBucket: "l2reum.appspot.com",
  messagingSenderId: "391507820315",
  appId: "1:391507820315:web:e2f898c4c3a595637d9dbe"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
