/** @format */

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Airdrop from "./pages/Airdrop";
import Devs from "./pages/Devs";
import About from "./pages/About";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
import Leaderboard from "./pages/Leaderboard";

function App() {
  const chains = [bsc, mainnet];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <React.Fragment>
      <BrowserRouter>
        <WagmiConfig config={wagmiConfig}>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/airdrop' element={<Airdrop />} />
            {/* <Route path='/devs' element={<Devs />} /> */}
            <Route path='/about' element={<About />} />
            <Route path='/leaderboard' element={<Leaderboard />} />
          </Routes>
          <div className=' absolute z-50'>
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              themeMode='dark'
              themeVariables={{
                "--w3m-background": "#e2107c",
                "--w3m-font-family": "Geom Graphic W03 Regular",
                "--w3m-background-color": "#e2107c",
                "--w3m-text-primary": "#000",
                "--w3m-color-bg-1": "#e2107c",
                "--w3m-color-bg-2": "#e2107c",
                "--w3m-color-bg-3": "#e2107c",
                "--w3m-color-fg-2": "#fff",
                "--w3m-color-fg-3": "#fff",
                "--w3m-color-fg-1": "#fff",
                "--w3m-accent-color": "#fff",
              }}
            />
          </div>{" "}
          <ToastContainer position='bottom-center' theme='dark' closeOnClick />
        </WagmiConfig>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
