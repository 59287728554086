import React from 'react'

const Footer = () => {
  return (
    <footer>
         <div className='absolute bottom-5 z-20 hidden animate-appear-expand-right items-center  sm:flex sm:left-[100px] sm:right-96 '>
          <div className='typography-brand-body flex w-full animate-appear-expand-right items-center gap-2.5 uppercase leading-[160%] text-[#fff]'>
            <div className='flex flex-1 justify-end gap-1 overflow-x-hidden text-[#33144aFF] opacity-50'>
              <svg
                className='h-[9px] min-w-max'
                fill='none'
                viewBox='0 0 524 8'>
                <path
                  d='M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z'
                  fill='currentColor'
                />
                <circle cx='189.847' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='194.714' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='199.581' cy='4.396' fill='currentColor' r='1.46' />
                <circle cx='279.219' cy='4.396' fill='currentColor' r='1.46' />
              </svg>
              <svg
                className='h-[9px] min-w-max'
                fill='none'
                viewBox='0 0 524 8'>
                <path
                  d='M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z'
                  fill='currentColor'
                />
                <circle cx='189.847' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='194.714' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='199.581' cy='4.396' fill='currentColor' r='1.46' />
                <circle cx='279.219' cy='4.396' fill='currentColor' r='1.46' />
              </svg>
              <svg
                className='h-[9px] min-w-max'
                fill='none'
                viewBox='0 0 524 8'>
                <path
                  d='M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z'
                  fill='currentColor'
                />
                <circle cx='189.847' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='194.714' cy='4.397' fill='currentColor' r='1.46' />
                <circle cx='199.581' cy='4.396' fill='currentColor' r='1.46' />
                <circle cx='279.219' cy='4.396' fill='currentColor' r='1.46' />
              </svg>
            </div>
            {/* <div className=' '>TVL / - - -</div> */}
            <div className='disabled:cursor-not-allowed sm:max-w-[206px]   min-h-[40px] sm:flex sm:justify-center sm:items-center transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full border border-[#ca8f30] shadow-md shadow-yellow-600'>
              TVL / - - -
            </div>
            <div className='hidden gap-1 opacity-50 min-[1260px]:flex'>
              <div className='h-[7px] w-[3px] bg-[#33144aFF]' />
              <div className='h-[7px] w-[3px] bg-[#33144aFF]' />
              <div className='h-[7px] w-[21px] bg-[#33144aFF]' />
            </div>
            {/* <div className='hidden min-[1260px]:block'>
            Total Users / 72,592
          </div> */}

            <div className='disabled:cursor-not-allowed sm:max-w-[206px] hidden min-[1260px]:flex  min-h-[40px] sm:flex sm:justify-center sm:items-center transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full border border-[#ca8f30] shadow-md shadow-yellow-600'>
              Total Users / 72,592
            </div>

            <div className='hidden gap-1 opacity-50 min-[1440px]:flex'>
              <div className='h-[7px] w-[14px] bg-[#33144aFF]' />
              <div className='h-[7px] w-[3px] bg-[#33144aFF]' />
            </div>
            <div className='disabled:cursor-not-allowed sm:max-w-[206px] hidden min-[1260px]:flex  min-h-[40px] sm:flex sm:justify-center sm:items-center transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full border border-[#ca8f30] shadow-md shadow-yellow-600'>
              Early Access Live
            </div>
            {/* <div className='hidden min-[1440px]:block'>Early Access Live</div> */}
          </div>
        </div>
        <div className='absolute z-20 hidden items-center gap-6 text-camo-400 xs:bottom-1 xs:right-10 xs:flex xs:max-sm:scale-75 sm:bottom-5 sm:right-20'>
          <a
            href='https://twitter.com/Blast_L2'
            rel='noreferrer'
            target='_blank'>
            <button
              aria-label='Twitter'
              className='text-camo-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed'>
              <svg style={{ height: 24, width: 24 }} viewBox='0 0 24 24'>
                <use xlinkHref='/icons/library.svg#twitter-filled' />
              </svg>
            </button>
          </a>
          <a
            href='https://discord.gg/blast-l2'
            rel='noreferrer'
            target='_blank'>
            <button
              aria-label='Discord'
              className='text-camo-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed'>
              <svg style={{ height: 24, width: 24 }} viewBox='0 0 24 24'>
                <use xlinkHref='/icons/library.svg#discord' />
              </svg>
            </button>
          </a>
        </div>
        <div className='absolute bottom-6 left-6 top-20 hidden w-1.5 animate-enter-fade flex-col text-[#e2107bFF] [animation-delay:0.5s] [animation-fill-mode:both] xs:flex [&>*]:opacity-50'>
          <div className='absolute inset-0 bottom-1/4 flex flex-col'>
            <div className='flex-0 animate-[flex-grow_20s_ease-in-out_alternate_infinite] [animation-delay:2s]' />
            <div className='flex w-full flex-col gap-4'>
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
            </div>
          </div>
          <div className='absolute inset-0 top-16 flex flex-col'>
            <div className='flex-0 animate-[flex-grow_14s_ease-in-out_alternate_infinite]' />
            <div className='flex w-full flex-col gap-1'>
              <div className='h-32 bg-current' />
              <div className='h-8 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-1 w-1 self-center rounded-full bg-current' />
            </div>
          </div>
          <div className='absolute inset-x-0 inset-y-1/4 flex flex-col'>
            <div className='flex-0 animate-[flex-grow_10s_ease-in-out_alternate_infinite]' />
            <div className='flex w-full flex-col gap-1'>
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-4' />
              <div className='h-1 w-1 self-center rounded-full bg-current' />
              <div className='h-1 w-1 self-center rounded-full bg-current' />
              <div className='h-1 w-1 self-center rounded-full bg-current' />
              <div className='h-4' />
              <div className='h-1 bg-current' />
            </div>
          </div>
          <div className='absolute inset-0 top-1/3 flex flex-col'>
            <div className='flex-0 animate-[flex-grow_10s_ease-in-out_alternate_infinite] [animation-delay:3s]' />
            <div className='flex w-full flex-col gap-1'>
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
            </div> 
          </div>
          <div className='absolute inset-0 top-1/2 flex flex-col'>
            <div className='flex-0 animate-[flex-grow_16s_ease-in-out_alternate_infinite]' />
            <div className='flex w-full flex-col gap-1'>
              <div className='h-8 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-8 bg-current' />
            </div>
          </div>
        </div>
        <div className='absolute bottom-20 right-6 top-6 hidden w-1.5 animate-enter-fade flex-col text-[#e2107bFF] [animation-delay:0.5s] [animation-fill-mode:both] xs:flex [&>*]:opacity-50'>
          <div className='absolute inset-0 bottom-40 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-4'>
              <div className='h-1 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_20s_ease-in-out_alternate_infinite] [animation-delay:2s]' />
          </div>
          <div className='absolute inset-0 bottom-24 top-1/2 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-1'>
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_10s_ease-in-out_alternate_infinite] [animation-delay:3s]' />
          </div>
          <div className='absolute inset-0 top-1/2 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-1'>
              <div className='h-8 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-8 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_16s_ease-in-out_alternate_infinite]' />
          </div>
        </div>
        <div className='absolute bottom-20 right-6 top-6 hidden w-1.5 animate-enter-fade flex-col text-[#e2107bFF] [animation-delay:0.5s] [animation-fill-mode:both] xs:flex [&>*]:opacity-50'>
          <div className='absolute inset-0 bottom-40 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-4'>
              <div className='h-1 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_20s_ease-in-out_alternate_infinite] [animation-delay:2s]' />
          </div>
          <div className='absolute inset-0 bottom-24 top-1/2 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-1'>
              <div className='h-1 bg-current' />
              <div className='h-1 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_10s_ease-in-out_alternate_infinite] [animation-delay:3s]' />
          </div>
          <div className='absolute inset-0 top-1/2 flex flex-col justify-end'>
            <div className='flex w-full flex-col gap-1'>
              <div className='h-8 bg-current' />
              <div className='h-1 bg-current' />
              <div className='h-8 bg-current' />
            </div>
            <div className='flex-0 animate-[flex-grow_16s_ease-in-out_alternate_infinite]' />
          </div>
        </div>
        <div className='absolute z-20 hidden items-center gap-6 text-camo-400 xs:bottom-1 xs:right-10 xs:flex xs:max-sm:scale-75 sm:bottom-5 sm:right-20'>
          <a href='https://twitter.com/L2reum' rel='noreferrer' target='_blank'>
            <button
              aria-label='Twitter'
              className='text-white uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height={24}
                width={24}
                fill='currentColor'
                viewBox='0 0 512 512'>
                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.*/}
                <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
              </svg>
            </button>
          </a>
          <a href='https://t.me/L2reum' rel='noreferrer' target='_blank'>
            <button
              aria-label='Telegram'
              className='text-white uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height={24}
                width={24}
                fill='currentColor'
                viewBox='0 0 496 512'>
                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.*/}
                <path d='M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z' />
              </svg>
            </button>
          </a>
        </div>
    </footer>
  )
}

export default Footer