/** @format */

import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();
  return (
    <header className='relative z-[11] flex h-max justify-between px-6 pb-3 pt-9 sm:ml-16 sm:pl-14 sm:pr-0 sm:pt-10 lg:pt-14 sm:mr-0'>
      <div className='flex flex-1 items-center justify-between gap-5 overflow-x-hidden'>
        <div className='flex flex-1 items-center justify-between sm:justify-end'>
          <a
            className='flex items-center sm:hidden'
            style={{ width: 136 }}
            href='/'> 
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={136}
              // height={32}
              style={{ color: "transparent" }}
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center sm:flex lg:hidden'
            style={{ width: 170 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              className=' aspect-auto'
              width={170}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center lg:flex'
            style={{ width: 256 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={256}
              // height={54}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>
          <nav className='ml-6 hidden items-center gap-2 xs:flex'>
            <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
              <Link
                className={`interactive-text text-gray-400  ${
                  pathname === "/" ? "text-pink-600 font-bold" : ""
                }`}
                to='/'>
                Home
              </Link>
            </div>
            <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
              <Link
                className={`interactive-text text-gray-400  ${
                  pathname === "/airdrop" ? "text-pink-600 font-bold" : ""
                }`}
                to='/airdrop'>
                Airdrop
              </Link>
            </div>
            <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
              <Link
                className={`interactive-text text-gray-400  ${
                  pathname === "/leaderboard" ? "text-pink-600 font-bold" : ""
                }`}
                to='/leaderboard'>
                Leaderboard
              </Link>
            </div>
            {/* <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
              <Link
                className={`interactive-text text-gray-400  ${
                  pathname === "/devs" ? "text-pink-600 font-bold" : ""
                }`}
                to='/devs'>
                Devs
              </Link>
            </div> */}
            <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
              <Link
                className={`interactive-text text-gray-400  ${
                  pathname === "/about" ? "text-pink-600 font-bold" : ""
                }`}
                to='/about'>
                About
              </Link>
            </div>
          </nav>
        </div>

        <div className='hidden overflow-x-auto xs:gap-10 sm:flex' />
      </div>
    </header>
  );
};

export default Header;
