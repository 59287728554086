/** @format */

import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header1 = () => {
  const { pathname } = useLocation();

  return (
    <header className='relative z-[11]  h-max  px-6 pb-3 pt-9 sm:ml-20 sm:pl-5 sm:pr-0 sm:pt-10 lg:pt-14 sm:mr-0'>
      <nav className='navbar navbar-expand-md '>
        <div className='container'>
          <a
            className='flex items-center sm:hidden'
            style={{ width: 136 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={136}
              // height={32}
              style={{ color: "transparent" }}
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center sm:flex lg:hidden'
            style={{ width: 170 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              className=' aspect-auto'
              width={170}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center lg:flex'
            style={{ width: 256 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={256}
              // height={54}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
            <span className='mr-7'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0  '>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
                  <Link
                    className={`interactive-text text-gray-400  ${
                      pathname === "/" ? "text-pink-600 font-bold" : ""
                    }`}
                    to='/'>
                    Home
                  </Link>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
                  <Link
                    className={`interactive-text text-gray-400  ${
                      pathname === "/airdrop" ? "text-pink-600 font-bold" : ""
                    }`}
                    to='/airdrop'>
                    Airdrop
                  </Link>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <Link
                    className={`interactive-text text-gray-400  ${
                      pathname === "/leaderboard"
                        ? "text-pink-600 font-bold"
                        : ""
                    }`}
                    to='/leaderboard'>
                    Leaderboard
                  </Link>
                </div>
                {/* <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <Link
                    className={`interactive-text text-gray-400  ${
                      pathname === "/devs" ? "text-pink-600 font-bold" : ""
                    }`}
                    to='/devs'>
                    Devs
                  </Link>
                </div> */}
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <Link
                    className={`interactive-text text-gray-400  ${
                      pathname === "/about" ? "text-pink-600 font-bold" : ""
                    }`}
                    to='/about'>
                    About
                  </Link>
                </div>
                {/* <a
                  className='typography-brand-body-l-caps group min-w-[216px] m-auto  animate-appear-slide-up self-center  text-yellow-300 xs:self-start'
                  href='https://app.l2reum.com'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <button className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-3 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                      Launch App
                    </button>
                  </div>
                </a> */}
              </ul>
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header1;
