/** @format */

import React from "react";

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Header1 from "../components/layout/Header1";

const Home = () => {
  React.useEffect(() => {
    document.title = " L2reum";
  }, []);
  return (
    <React.Fragment>
      <div className='relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen'>
        <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
          <div className='relative z-10 flex flex-1 flex-col pb-16 h-auto sm:h-full bg-img'>
            <div className='flex flex-1 sm:overflow-hidden'>
              <div className='flex flex-1 flex-col'>
                <div className='sticky top-[14px] z-10 sm:top-[13px]'>
                  <div className='relative'>
                    <Header1 />
                    {/* <Header /> */}
                    <div className='absolute bottom-0 left-0 right-0 transition-opacity duration-300 opacity-0'>
                      <div className='w-full h-[2px] bg-camo-500' />
                    </div>
                    <div className='absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-0 backdrop-blur-[12px]' />
                  </div>
                </div>
                <main className='relative flex h-full flex-1 px-6 pt-6  lg:pt-14 overflow-hidden sm:pr-0'>
                  <div className='flex flex-1 flex-col mt-8 lg:mt-0 animate-enter-fade gap-12'>
                    <div className='flex flex-col gap-8 text-center'>
                      <h1 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative '>
                        The L2 that helps you earn more
                        <span
                          className='mt-2 block overflow-hidden rounded-bl-lg rounded-tr-lg bg-[#33144A] align-middle transition-[width] duration-1000 xs:ml-4 xs:mt-0 xs:inline-block'
                          style={{ height: 53, width: 125 }}>
                          <span
                            className='flex flex-col items-start text-[#E2107B] transition-transform duration-1000 '
                            style={{ transform: "translateY(calc(0px))" }}>
                            <span className='px-2 leading-[125%] xs:px-3'>
                              BNB
                            </span>
                            <span className='px-2 leading-[125%] xs:px-3'>
                              USD
                            </span>
                            <span className='px-2 leading-[125%] xs:px-3'>
                              Yield
                            </span>
                          </span>
                        </span>
                      </h1>
                      <h3 className='typography-brand-heading-3 max-w-[28ch] m-auto animate-appear-slide-up text-gray-400'>
                        The only Ethereum L2 with native yield for BNB and
                        stablecoins. Airdrop now live.
                      </h3>
                      <Link
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'
                        to='/airdrop'>
                        <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                          <button className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                            Join Early Access
                          </button>
                        </div>
                      </Link>
                    </div>
                    <div className='text-[#E2107B] xs:hidden'>
                      <svg
                        className='w-full'
                        fill='none'
                        height={15}
                        width={1177}
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                          fill='currentColor'
                        />
                        <circle
                          cx='843.128'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='916.059'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='979.06'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='847.995'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='920.926'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='983.927'
                          cy='11.374'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='852.862'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='925.794'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <circle
                          cx='988.794'
                          cy='11.37'
                          fill='currentColor'
                          r='1.46'
                        />
                        <path
                          d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='flex justify-center xs:hidden'>
                      <div className='flex flex-col items-center gap-2'>
                        <div className='typography-brand-body-l-caps text-gray-300'>
                          TVL
                        </div>
                        <div className='typography-brand-heading-2 max-w-[80vw] overflow-hidden text-ellipsis text-white'>
                          - - -
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className='bg-black duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity' />
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
