/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import CountUp from "react-countup";
import { firebase } from "../components/utils/Firebase";
import useRequest from "@ahooksjs/use-request";
import Footer from "../components/layout/Footer";
import Header1 from "../components/layout/Header1";

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const lowercasedFilter = searchQuery.toLowerCase();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://l2reumapi.onrender.com/api/getusers",
      );
      setUsers(response?.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 2) + "..." + str.slice(-1) : str;
  }
  const handleSearch = event => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter(item =>
    Object.keys(item).some(
      key =>
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(lowercasedFilter),
    ),
  );
  React.useEffect(() => {
    document.title = "L2reum - Leaderboard";
  }, []);

  // const fetchState = fetchData();

  useRequest(fetchData, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchData, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  useEffect(() => {
    run();
    return () => {
      cancel();
    };
  }, [run, cancel]);

  const auth = getAuth(firebase);
  const [score, setScore] = useState(null);

  const twitterLogin = async () => {
    try {
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credentials = TwitterAuthProvider.credentialFromResult(result);
      const token = credentials.accessToken;
      const secret = credentials.secret;
      const userLogin = result.user;
      const twitter_id = userLogin?.uid;
      axios.post("https://l2reumapi.onrender.com/api/getScore", {
        twitter_id,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      if (authUser) {
        const twitter_id = authUser?.uid;
        if (twitter_id) {
          try {
            const getScoreResponse = await axios.post(
              "https://l2reumapi.onrender.com/api/getScore",
              {
                twitter_id,
              },
            );
            // console.log(getScoreResponse?.data);
            setScore(getScoreResponse?.data?.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
      } else {
        // User is signed out.
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <React.Fragment>
      <div className='relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen'>
        <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
          <div className='relative z-10 flex flex-1 flex-col pb-16 h-auto sm:h-full'>
            <marquee className='bg-[#E2107B] position-absolute  w-full  shadow-xl  items-center px-2  py-2 font-para font-semibold text-sm text-white end-0 left-0 top-0  '>
              {filteredUsers
                .filter(person => person.spin)
                .map((person, id) => (
                  <span className='text-sm font-semibold mb-0  mx-4' key={id}>
                    <strong className='text-white'>{id + 1}</strong>:{" "}
                    <span className='text-gray-300'>{person?.userName}</span>
                  </span>
                ))}
            </marquee>
            <div className='flex flex-1 sm:overflow-hidden'>
              <div className='flex flex-1 flex-col'>
                <div className='sticky top-[14px] z-10 sm:top-[13px]'>
                  <div className='relative'>
                    <Header1 />
                    {/* <Header /> */}
                    <div className='absolute bottom-0 left-0 right-0 transition-opacity duration-300 opacity-0'>
                      <div className='w-full h-[2px] bg-camo-500' />
                    </div>
                    <div className='absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-0 backdrop-blur-[12px]' />
                  </div>
                </div>
                <main className='relative flex h-full flex-1  pt-6 sm:ml-16 sm:pl-14 sm:pr-14 lg:pt-4 overflow-hidden'>
                  <div>
                    <div className='flex-grow w-full  m-auto    top-0 md:sticky backdrop-blur-xl '>
                      <div className='container m-auto px-6  md:px-10  '>
                        <div className='flex justify-between flex-wrap items-center '>
                          <div>
                            <h3 className='typography-brand-heading-2 w-full text-white xs:max-w-2xl '>
                              Leaderboard
                            </h3>
                            <p className='typography-brand-heading  text-gray-300'>
                              Bridge & Invite friends to rank up
                            </p>
                          </div>
                          <div className=' w-full max-w-sm ms-auto flex items-center pr-0 md:pr-20'>
                            <form className='formSer ms-auto'>
                              <button>
                                <svg
                                  width={17}
                                  height={16}
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  role='img'
                                  aria-labelledby='search'>
                                  <path
                                    d='M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9'
                                    stroke='currentColor'
                                    strokeWidth='1.333'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </button>
                              <input
                                className='input'
                                placeholder='Search...'
                                required=''
                                type='text'
                                value={searchQuery}
                                onChange={handleSearch}
                              />
                              <button className='reset' type='reset'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='h-6 w-6'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  stroke='currentColor'
                                  strokeWidth={2}>
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M6 18L18 6M6 6l12 12'
                                  />
                                </svg>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div className='pr-0 md:pr-20 '>
                          <React.Fragment>
                            <div className='row  ml-0 pl-0 mt-4 py-3'>
                              <div className='w-full text-[#e2107c] my-4 m-auto'>
                                <svg
                                  className='w-full'
                                  fill='none'
                                  height={15}
                                  width={1177}
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                                    fill='currentColor'
                                  />
                                  <circle
                                    cx='843.128'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='916.059'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='979.06'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='847.995'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='920.926'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='983.927'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='852.862'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='925.794'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='988.794'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <path
                                    d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                                    stroke='currentColor'
                                    strokeWidth='1.5'
                                  />
                                </svg>
                              </div>
                              <div className='col-2'>
                                <div className='font-head'>
                                  <h5 className='text-sm font-bold  mb-0 text-yellow-300'>
                                    Rank
                                  </h5>
                                </div>
                              </div>
                              <div className='col-4'>
                                <div className='font-head'>
                                  <h5 className='text-sm font-bold mb-0 text-yellow-300'>
                                    Name
                                  </h5>
                                </div>
                              </div>
                              <div className='col-3'>
                                <div className='font-head'>
                                  <h5 className='text-sm font-bold mb-0 text-yellow-300'>
                                    Invite By
                                  </h5>
                                </div>
                              </div>
                              <div className='col-3'>
                                <div className='font-head text-end'>
                                  <h5 className='text-sm font-bold mb-0 text-yellow-300 '>
                                    L2R Tokens
                                  </h5>
                                </div>
                              </div>
                              <div className='w-full text-[#e2107c] my-4 m-auto rotate-180'>
                                <svg
                                  className='w-full'
                                  fill='none'
                                  height={15}
                                  width={1177}
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                                    fill='currentColor'
                                  />
                                  <circle
                                    cx='843.128'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='916.059'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='979.06'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='847.995'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='920.926'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='983.927'
                                    cy='11.374'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='852.862'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='925.794'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <circle
                                    cx='988.794'
                                    cy='11.37'
                                    fill='currentColor'
                                    r='1.46'
                                  />
                                  <path
                                    d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                                    stroke='currentColor'
                                    strokeWidth='1.5'
                                  />
                                </svg>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                    <div className=' w-full  m-auto   pt-3'>
                      <div className='container m-auto px-6 text-yellow-300 md:px-10  '>
                        <div className='pr-0 md:pr-14'>
                          {filteredUsers.length === 0 ? (
                            <div className='w-full h-72 flex justify-center items-center'>
                              <div className='spinner'>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          ) : (
                            <div className='row  ml-0 pl-0  '>
                              {filteredUsers
                                .filter(person => person.spin)
                                .sort((a, b) => b.spinAmount - a.spinAmount)
                                .map((person, id) => (
                                  <div className='row  ' key={person.id}>
                                    <div className='col-2'>
                                      <div className='font-head '>
                                        <div className='disabled:cursor-not-allowed  disabled:bg-camo-300 w-20 flex items-center justify-center disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-[#33144A] focus-visible:bg-white hover:bg-white text-gray-300'>
                                          <h5 className='text-sm font-semibold mb-0 text-white '>
                                            {id + 1}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div className=' flex items-center justify-start'>
                                        <div>
                                          <img
                                            className='h-8 w-8   rounded-full '
                                            src={person.imgUrl}
                                            alt=''
                                          />
                                        </div>
                                        <h5 className='text-sm ml-2 mb-0 font-semibold font-para text-white font'>
                                          {person.userName}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className='col-3'>
                                      <div className='font-para'>
                                        <h5 className='text-sm font-semibold mb-0 text-white'>
                                          {person.refName}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className='col-3 text-end'>
                                      <div className='font-head'>
                                        <h5 className='text-sm  text-white mb-0 flex items-center gap-2 justify-end'>
                                          <CountUp
                                            delay={2}
                                            start={0}
                                            end={person?.spinAmount}
                                          />{" "}
                                          <img
                                            alt=''
                                            loading='lazy'
                                            width={24}
                                            height={24}
                                            decoding='async'
                                            data-nimg={1}
                                            srcSet='images/logo-cr.svg'
                                            src='images/logo-cr.svg'
                                            style={{ color: "transparent" }}
                                          />
                                        </h5>
                                      </div>
                                    </div>
                                    <div className='w-full text-[#ffffff27] my-4 m-auto'>
                                      <svg
                                        className='w-full'
                                        fill='none'
                                        height={15}
                                        width={1177}
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                          d='M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z'
                                          fill='currentColor'
                                        />
                                        <circle
                                          cx='843.128'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='916.059'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='979.06'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='847.995'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='920.926'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='983.927'
                                          cy='11.374'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='852.862'
                                          cy='11.37'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='925.794'
                                          cy='11.37'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <circle
                                          cx='988.794'
                                          cy='11.37'
                                          fill='currentColor'
                                          r='1.46'
                                        />
                                        <path
                                          d='M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5'
                                          stroke='currentColor'
                                          strokeWidth='1.5'
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className='bg-black duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity' />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Leaderboard;
